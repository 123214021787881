<template>
  <div class="tags-wrap">
    <div class="tags">
      <div class="tag" :class="{ active: item.path == curPath }" v-for="item in tagList" :key="item.path" :title="item.name" @click="handleTagClick(item)" @contextmenu.prevent="handleOpenMenu(item, $event)">
        <span class="label mr5"> {{ item.name }} </span>
        <i class="el-icon-close" v-if="tagList.length > 1" @click.stop="handleDeleteTag(item)" />
      </div>
    </div>
    <div
      class="contextmenu"
      v-show="showMenu"
      :style="{
        left: menuLeft + 'px',
      }"
    >
      <div @click="handleDeleteTag(selectTag)">关闭当前标签</div>
      <div @click="handleDeleteOtherTag(selectTag)">关闭其他标签</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import common from '@/common/index'
export default {
  data() {
    return {
      menuLeft: '',
      showMenu: false,
      selectTag: null,
    }
  },
  computed: {
    ...mapState(['curSystem']),
    tagList() {
      return common.getOpenTag(this.curSystem.code)
    },
    curPath() {
      return this.$route.path
    },
  },
  watch: {
    showMenu(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
  methods: {
    handleTagClick(item) {
      this.$router.push({
        path: item.path,
        query: item.query,
      })
    },
    handleDeleteTag(item) {
      if (this.tagList.length > 1) {
        common.deleteTag(item)
      }
    },
    handleOpenMenu(item, e) {
      if (this.tagList.length < 2) return
      let node = e.target
      if (node.classList[0] != 'tag') {
        node = node.parentNode
      }
      this.menuLeft = node.getBoundingClientRect().left
      this.selectTag = item
      this.showMenu = true
    },
    handleDeleteOtherTag(item) {
      if (this.tagList.length > 1) {
        common.deleteTag(item, 'other')
      }
    },
    closeMenu() {
      this.showMenu = false
    },
  },
}
</script>
<style lang="scss" scoped>
.tags-wrap {
  display: flex;
  width: calc(100vw - 450px);
  overflow: auto;
  .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: relative;
    font-size: 12px;
    box-sizing: border-box;
    .tag {
      width: 110px;
      line-height: 45px;
      display: flex;
      color: #9d9d9d;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .label {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover {
        background: transparentize($theme-color, 0.9);
      }
    }
    .active {
      color: $theme-color;
      background: transparentize($theme-color, 0.9);
      transition: all 0.3s ease;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: $theme-color;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: fixed;
    top: 50px;
    list-style-type: none;
    padding: 10px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 0px 0px 10px 3px rgba($color: #000000, $alpha: 0.09);
    div {
      height: 37px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;
      &:hover {
        background: #ebf8f6;
      }
    }
  }
}
</style>
