const routes = [
  {
    path: '/operate/',
    redirect: '/operate/appHomePage',
    children: [
      {
        path: 'appHomePage',
        name: '应用首页',
        component: () => import('@/views/operate/appMarket/appHomePage'),
      },
      {
        path: 'appDecoration',
        name: '应用装饰',
        component: () => import('@/views/operate/appMarket/appDecoration'),
      },
      {
        path: 'appList',
        name: '应用列表',
        component: () => import('@/views/operate/appMgt/appList'),
      },
      {
        path: 'appCategory',
        name: '应用类目',
        component: () => import('@/views/operate/appMgt/appCategory')
      },
      {
        path: 'appTag',
        name: '应用标签',
        component: () => import('@/views/operate/appMgt/appTag')
      },
      {
        path: 'accountList',
        name: '账号列表',
        component: () => import('@/views/operate/companyMgt/account')
      },
      {
        path: 'returnVisit',
        name: '回访列表',
        component: () => import('@/views/operate/companyMgt/returnVisit')
      },
      {
        path: 'consultOrder',
        name: '咨询工单',
        component: () => import('@/views/operate/orderMgt/consultOrder')
      },
      {
        path: 'operateOrder',
        name: '运维工单',
        component: () => import('@/views/operate/orderMgt/operateOrder')
      },
      {
        path: 'complainOrder',
        name: '投诉工单',
        component: () => import('@/views/operate/orderMgt/complainOrder')
      },
      {
        path: 'suggestOrder',
        name: '建议工单',
        component: () => import('@/views/operate/orderMgt/suggestOrder')
      },
      {
        path: 'adviceOrder',
        name: '吐槽工单',
        component: () => import('@/views/operate/orderMgt/adviceOrder/index')
      },
      {
        path: 'logList',
        name: '日志列表',
        component: () => import('@/views/operate/logMgt')
      },
      // 工作流相关
      {
        path: 'flowMgt/personWork',
        name: '个人办公',
        component: () => import('@/views/operate/flowMgt/personWork')
      },
      {
        path: 'flowMgt/flowModal',
        name: '流程模型',
        component: () => import('@/views/operate/flowMgt/flowModal')
      },
      {
        path: "fileMgt",
        name: "文档管理",
        component: () => import("@/views/operate/fileMgt/index"),
      },
      {
        path: "notice",
        name: "站内信",
        component: () => import("@/views/operate/notice/index"),
      },
    ],
  },
]
export default routes
