const routes = [
  {
    path: "/devops/",
    redirect: "/devops/sysFramework",
    children: [
      {
        path: "sysFramework",
        name: "系统框架",
        component: () =>
          import("@/views/devops/frameworkMgt/sysFramework/index"),
      },
      {
        path: "frontFramework",
        name: "前端框架",
        component: () =>
          import("@/views/devops/frameworkMgt/frontFramework/index"),
      },
      {
        path: "frontTemplate",
        name: "前端模板",
        component: () =>
          import("@/views/devops/frameworkMgt/frontTemplate/index"),
      },
      {
        path: "backFramework",
        name: "后端框架",
        component: () =>
          import("@/views/devops/frameworkMgt/backFramework/index"),
      },
      {
        path: "basalRely",
        name: "基础依赖",
        component: () => import("@/views/devops/relyMgt/basalRely/index"),
      },
      {
        path: "otherRely",
        name: "第三方依赖",
        component: () => import("@/views/devops/relyMgt/otherRely/index"),
      },
      ////////////////////////////////////////////////////////////////////////////////////////
      {
        path: "global/roleData",
        name: "全局角色数据",
        component: () => import("@/views/auth/userMgt/roleList2"),
        props: { rangeFlag: "global" },
      },
      {
        path: "global/baseData",
        name: "全局基础数据",
        component: () => import("@/views/auth/configMgt/baseData"),
        props: { rangeFlag: "global" },
      },
      {
        path: "app/roleData",
        name: "应用级角色数据",
        component: () => import("@/views/auth/userMgt/roleList2"),
        props: { rangeFlag: "app" },
      },
      {
        path: "app/baseData",
        name: "应用级基础数据",
        component: () => import("@/views/auth/configMgt/baseData"),
        props: { rangeFlag: "app" },
      },
      //////////////////以下待移除/////////////////////////

      {
        path: "msgCluster",
        name: "消息集群",
        component: () => import("@/views/devops/cluster/msgCluster/index"),
      },

      {
        path: "msgDictionary",
        name: "字典数据",
        component: () =>
          import("@/views/devops/dictionary/msgDictionary/index"),
      },

      {
        path: "dictionaryType",
        name: "字典类型",
        component: () =>
          import("@/views/devops/dictionary/dictionaryType/index"),
      },
      {
        path: "plugInMgt",
        name: "插件管理",
        component: () => import("@/views/devops/dictionary/plugInMgt/index"),
      },

      //
      {
        path: "engineList",
        name: "引擎列表",
        component: () => import("@/views/devops/pageEngine/engineList/index"),
        meta: {
          parentCode: "engineList",
        },
      },
      {
        path: "pageEngineConfig/",
        component: () =>
          import(
            "@/views/devops/pageEngine/engineList/versionMgt/config/components/menuLayout"
          ),
        children: [
          {
            path: "pageModule",
            name: "页面模板",
            component: () =>
              import(
                "@/views/devops/pageEngine/engineList/versionMgt/config/pageModule/index"
              ),
            meta: {
              breadList: [{ name: "页面模板" }],
            },
          },
          {
            path: "icon",
            name: "icon图标",
            component: () =>
              import(
                "@/views/devops/pageEngine/engineList/versionMgt/config/icon/index"
              ),
            meta: {
              breadList: [{ name: "icon图标" }],
            },
          },
          {
            path: "codeConfig",
            name: "代码配置",
            component: () =>
              import(
                "@/views/devops/pageEngine/engineList/versionMgt/config/codeConfig/index"
              ),
            meta: {
              breadList: [{ name: "代码配置" }],
            },
          },
        ],
      },

      {
        path: "baseConfig",
        name: "基础配置",
        component: () => import("@/views/devops/resMgt/baseConfig/index"),
      },
      {
        path: "baseConfigDetail",
        name: "基础配置详情",
        component: () => import("@/views/devops/resMgt/baseConfig/index"),
      },
      {
        path: "job",
        name: "任务调度",
        component: () => import("@/views/devops/resMgt/job/index"),
      },
      {
        path: "cmp_flow",
        name: "资源流程",
        component: () => import("@/views/devops/resMgt/resource/index"),
      },
    ],
  },
];
export default routes;
