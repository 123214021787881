<!-- 头部菜单栏 -->
<template>
  <header class="layout-header border-bottom-line font14 pl10 flex-b">
    <div class="flex-c ml10">
      <el-dropdown trigger="click" @command="handleEvent('system', $event)">
        <span class="el-dropdown-link cursor text-theme">
          {{ curSystem.name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in authMenu" :key="item.code" :command="item.code" :class="{ active: curSystem.code === item.code }" class="text-center">
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <tags class="ml20" />
    </div>
    <div class="header-right flex">
      <div class="user-info mr10 flex-cc">
        <el-dropdown @command="handleEvent($event)">
          <div class="el-dropdown-link cursor flex-c">
            <el-avatar icon="el-icon-user-solid" size="medium" v-if="userInfo.details" :src="userInfo.details.generalAccount.iconUrl"></el-avatar>
            <div class="name ml5" v-if="userInfo.details">{{ userInfo.details.generalAccount.name }}</div>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="text-center" :command="item.type" v-for="item in items" :key="item.type">
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <form method="post" :action="logoutAction" v-show="false"></form> -->
    <user-info-modal :show.sync="showUserInfoModal" />
    <pwd-modal :show.sync="showPwdModal" @success="loginout" />
  </header>
</template>

<script>
import { mapState } from 'vuex'
import tags from './tags'
import userInfoModal from './modal/userInfoModal'
import pwdModal from './modal/pwdModal'
import common from '@/common'
import http from '@/utils/axios'
export default {
  components: { tags, userInfoModal, pwdModal },
  data() {
    return {
      items: [
        // { label: '个人信息', type: 'userInfo' },
        { label: '修改密码', type: 'password' },
        { label: '退出登录', type: 'loginout' },
      ],
      logoutAction: `/logout`,
      showUserInfoModal: false,
      showPwdModal: false,
    }
  },
  computed: { ...mapState(['isFoldSlider', 'userInfo', 'curSystem', 'authMenu']) },
  watch: {
    $route: {
      handler(val) {
        this.routeChange(val)
      },
      immediate: true,
    },
  },
  methods: {
    handleEvent(type, path) {
      switch (type) {
        case 'system': {
          const appItem = this.authMenu.filter((i) => i.code == path)[0]
          this.$store.commit('SET_CURRENT_SYS', appItem)
          this.$router.push({ path: `/${path}` })
          break
        }
        case 'userInfo':
          this.showUserInfoModal = true
          break
        case 'loginout':
          this.$confirm('确定退出登录吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            // const form = document.querySelector('form')
            // form.submit()
            this.loginout()
          })
          break
        case 'password':
          this.showPwdModal = true
          break
      }
    },
    loginout() {
      http.post('/logout', null, { baseURL: '' })
    },
    routeChange(val) {
      this.authMenu.forEach((item) => {
        if (_.startsWith(this.$route.path, `/${item.code}/`)) {
          this.$store.commit('SET_CURRENT_SYS', item)
        }
      })
      common.updateTag(val)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item.active {
  color: $theme-color;
}
::v-deep .el-dropdown-menu__item {
  line-height: 35px !important;
}
::v-deep .el-dropdown-menu--mini,
.el-dropdown-menu {
  width: 100px !important;
}
header {
  background: #fff;
  height: 45px;
  // line-height: 45px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .title {
    white-space: nowrap;
    overflow: hidden;
    width: 170px;
  }
  .name {
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.el-dropdown {
  font-size: 13px;
}
</style>
