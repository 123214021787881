/**
 * @公共方法类
 * **/

import store from '@/store/index'
import router from '@/router/index'
import commonApi from '@/api/common'

//  获取页签
const getOpenTag = (sys) => {
  let { openTags } = store.state
  return sys ? openTags[sys] : openTags
}

//  更新页签
const updateTag = (route) => {
  let openTags = JSON.parse(sessionStorage.getItem('openTags')) || getOpenTag()
  const { code } = store.state.curSystem
  const { name, path, query } = route
  if (!openTags[code]) {
    return
  }
  let isExist = openTags[code].some((i) => i.path == path)
  if (!isExist) {
    openTags[code].push({
      name,
      path,
      query,
    })
  } else {
    openTags[code].forEach((i) => {
      if (i.path == path && i.query != query) {
        i.query = query
      }
    })
  }
  sessionStorage.setItem('openTags', JSON.stringify(openTags))
  store.commit('SET_OPEN_TAGS', openTags)
}

//  删除页签
const deleteTag = (tag, type = '') => {
  let openTags = JSON.parse(sessionStorage.getItem('openTags')) || getOpenTag()
  const { code } = store.state.curSystem
  if (type == 'other') {
    openTags[code] = openTags[code].filter((i) => i.path == tag.path)
    router.push(openTags[code][0].path)
  } else {
    openTags[code] = openTags[code].filter((i) => i.path != tag.path)
    const currentPath = router.currentRoute.path
    if (currentPath == tag.path && openTags[code].length) {
      router.push(openTags[code][0].path)
    }
  }
  sessionStorage.setItem('openTags', JSON.stringify(openTags))
  store.commit('SET_OPEN_TAGS', openTags)
}

// 获取用户信息
const getUserInfo = () => {
  commonApi.getUserInfo().then((res) => {
    res.data && store.commit('SET_USER_INFO', res.data)
  })
}

// 获取权限菜单
const getAuthTree = async () => {
  let list = (await commonApi.getAuthTree()).data
  let openTags = {}
  let systemList = []
  list.forEach((i) => {
    openTags[i.code] = []
    systemList.push({
      systemName: i.name,
      systemCode: i.code,
      id: i.id,
    })
  })
  store.commit('SET_AUTH_MENU', list)
  store.commit('SET_CURRENT_SYS', list.length ? list[0] : {})
  store.commit('SET_SYSTEM', systemList)
  store.commit('SET_OPENTAGS', openTags)
}

const getApiCata = () => {
  const { code } = store.state.curSystem
  return code == 'auth' ? 'back_end' : 'front_desk'
}

export default {
  getOpenTag,
  updateTag,
  deleteTag,
  getUserInfo,
  getAuthTree,
  getApiCata,
}
