<template>
  <el-dialog title="个人信息" width="550px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form label-width="70px" :model="form" :rules="rules" ref="form">
      <el-form-item label="登录账号" prop="account">
        <el-input placeholder="请输入" v-model="form.account" clearable></el-input>
      </el-form-item>
      <el-form-item label="账号昵称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input placeholder="请输入" v-model="form.phone" clearable></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input placeholder="请输入" v-model="form.email" clearable></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="iconUrl">
        <image-upload :url.sync="form.iconUrl" @success="handleAvatarSuccess" :params="{ businessType: 'system_icon' }" :fileSize="2" fileType="image" />
      </el-form-item>
      <el-form-item label="外部账号">
        <el-switch v-model="form.isOuter"> </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/common/index'
import common from '@/common'
import imageUpload from '@/components/imageUpload'
import { mapState } from 'vuex'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  components: { imageUpload },
  data() {
    return {
      form: {},
      rules: {
        account: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入账号昵称', trigger: 'blur' }],
      },
    }
  },
  computed: { ...mapState(['userInfo']) },
  watch: {
    show(val) {
      if (val) {
        this.form = _.cloneDeep(this.userInfo)
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        api.userInfoModify(this.form).then(() => {
          this.$msg.success('操作成功')
          common.getUserInfo()
          this.close()
        })
      })
    },
    handleAvatarSuccess(res, file) {
      const { ossUrl } = res.data
      this.$set(this.form, 'iconUrl', ossUrl)
    },
  },
}
</script>
