import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import jsCookie from 'js-cookie'
import ElementUI from 'element-ui'
import '@/utils/directives'
import '@/assets/css/element-variables.scss'
import '@/components/index'

Vue.config.productionTip = false
Vue.prototype.$cookie = jsCookie

import msg from '@/utils/message'
Vue.use(msg)

Vue.use(ElementUI, {
  size: 'mini',
})

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
