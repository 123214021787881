const routes = [
  {
    path: '/oauth',
    redirect: '/oauth/client',
    children: [
      {
        path: 'client',
        name: '客户端配置',
        component: () => import('@/views/oauth/client/index'),
      },
      {
        path: 'access',
        name: '访问统计',
        component: () => import('@/views/oauth/access/index'),
      },
    ],
  },
]
export default routes
