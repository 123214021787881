<!-- 左侧栏 -->
<template>
  <common-layout>
    <template #header>
      <header class="flex-c">
        <div class="font-bold title font14 ml20">
          <i class="el-icon-s-flag mr5"></i>
          <span v-show="!isFoldSlider">{{ systemName }}</span>
        </div>
      </header>
    </template>
    <template>
      <el-menu :default-active="$route.path" :collapse="isFoldSlider" :collapse-transition="false" :default-openeds="defaultOpeneds" router background-color="#001529" text-color="hsla(0,0%,100%,.65)" class="slider h100 w100">
        <el-submenu :index="item.code" v-for="(item, index) in menuList" :key="index">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item v-for="(menu, num) in item.children" :key="num" :index="menu.path" :class="{ active: $route.path === menu.path || menu.code === $route.meta.parentCode }" :route="{ path: menu.path, query: $route.query }" @select="swichMenu(menu.path)">
            {{ menu.name }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </template>
    <template #footer>
      <footer class="flex-c">
        <div class="cursor ml10" @click="handleChangeFold">
          <i :class="isFoldSlider ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </div>
      </footer>
    </template>
  </common-layout>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['leftMenuData'],
  data() {
    return {
      systemName: '开发平台后台',
      menuList: [],
      defaultOpeneds: [],
      // 页面引擎菜单
      engineData: [
        {
          icon: '&#xe62f;',
          name: '引擎配置',
          code: 'pageEngineConfig',
          children: [
            {
              name: '页面模板',
              code: 'pageModule',
              path: '/devops/pageEngineConfig/pageModule',
            },
            {
              name: 'icon图标',
              code: 'icon',
              path: '/devops/pageEngineConfig/icon',
            },
            {
              name: '代码配置',
              code: 'codeConfig',
              path: '/devops/pageEngineConfig/codeConfig',
            },
          ],
        },
      ],
      defaultMenuList: []
    }
  },
  computed: { ...mapState(['isFoldSlider', 'curSystem']) },
  watch: {
    leftMenuData: {
      handler(val) {
        this.menuList = this.menuTran(val)
        this.defaultOpeneds = this.getDefaultOpeneds(val)
        this.defaultMenuList = _.cloneDeep(this.menuList)
        this.dealMenu(this.$route.path)
      },
      immediate: true,
    },
    '$route.path'(val){
      this.dealMenu(val)
    }
  },
  methods: {
    menuTran(list) {
      list &&
        list.forEach((item) => {
          if (item.children && item.children.length) {
            if (item.children[0].type == 'resource') {
              item.path = item.children[0].code
            } else {
              this.menuTran(item.children)
            }
          }
        })
      return list
    },
    getDefaultOpeneds(list) {
      return list && list.map((i) => i.code)
    },
    handleChangeFold() {
      this.$store.commit('SET_FOLD_SLIDER_STATUS', !this.isFoldSlider)
    },
    dealMenu(val){
      let isEngine = _.startsWith(val, '/devops/pageEngineConfig')
      // 如果是前端引擎的路由则菜单直接取页面引擎菜单数据
      if(isEngine) {
        this.menuList = this.engineData
        this.defaultOpeneds = ['pageEngineConfig']
      }else {
        this.menuList = this.defaultMenuList
      }
      
    }
  },
}
</script>
<style scoped lang="scss">
header {
  height: 55px;
  background: #0a1b2a;
  color: #ffffff;
}
footer {
  height: 50px;
  background: #0a1b2a;
  color: #ddd;
  font-size: 20px;
}
.slider {
  overflow-x: hidden;
  overflow-y: auto;
  .title {
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
  }
  .el-menu {
    .active {
      // background: #009091 !important;
      transition: all 0.3s ease;
      color: #fff !important;
    }
    .el-menu-item:hover {
      color: #fff !important;
    }
  }
}
.el-menu {
  border-right: none !important;
}
.el-menu--collapse {
  width: 100%;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}
</style>
