import Vue from "vue";

Vue.directive("drag", {
  //1.指令绑定到元素上回立刻执行bind函数，只执行一次
  //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
  //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
  bind: function (el) {},
  //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
  inserted: function (el) {
    el.onmousedown = function (e) {
      let disx = e.pageX - el.offsetLeft;
      let disy = e.pageY - el.offsetTop;
      let moveX = 0;
      let moveY = 0;
      let advicePosition = {
        left: 0,
        top: 0,
      };
      document.onmousemove = function (f) {
        advicePosition.left = f.pageX - disx + "px";
        advicePosition.top = f.pageY - disy + "px";
        el.style.left = advicePosition.left;
        el.style.top = advicePosition.top;
        moveX = f.pageX - e.pageX;
        moveY = f.pageY - e.pageY;
      };
      document.onmouseup = function (e) {
        document.onmousemove = document.onmouseup = null;
        if (Math.abs(moveX) > 10 || Math.abs(moveY) > 10) {
          localStorage.setItem(
            "advicePosition",
            JSON.stringify(advicePosition)
          );
          //打标签，根据变迁判断是否需要执行点击事件，避免点击事件与拖动事件冲突
          el.setAttribute("data-flag", "drag");
          setTimeout(() => {
            el.setAttribute("data-flag", "no-drag");
          }, 500);
        }
      };
    };
  },
  //当VNode更新的时候会执行updated，可以触发多次
  updated: function (el) {},
});
// 实现Element UI中下拉框滚动加载
Vue.directive("loadmore", {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    SELECTWRAP_DOM.addEventListener("scroll", function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (CONDITION) {
        binding.value();
      }
    });
  },
});
