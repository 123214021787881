import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'


Vue.use(Vuex)
const state = {
  isFoldSlider: false,
  userInfo: {},
  systemList: [],
  curSystem: {},
  leftMenuData: [],
  currentEnv: 'dev', // 当前环境
  envList: [
    {
      label: '开发环境',
      value: 'dev',
    },
    {
      label: '测试环境',
      value: 'test',
    },
    {
      label: '压测环境',
      value: 'perf',
    },
    {
      label: '验收环境',
      value: 'uat',
    },
    {
      label: '生产环境',
      value: 'prod',
    },
  ],
  categoryCode: 'share-project',
  categoryName: '共享项目',
  openTags: {
    // devops: [],
    // auth: [],
    // operate: [],
  },
  authMenu: [],
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
