<template>
  <el-upload class="common-upload" action="/api/tenant-user/file/upload" :data="params" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
    <el-image fit="cover" v-if="url" :src="url" class="common-upload-image" />
    <i v-else class="common-upload-icon el-icon-plus avatar-uploader-icon"></i>
    <div v-if="url" class="delete-box" @click.stop="handleDelete">
      <i class="el-icon-close"></i>
    </div>
  </el-upload>
</template>
<script>
export default {
  props: {
    url: {
      default: "",
      type: String,
    },
    fileSize: {
      default: 0,
      type: Number,
    },
    fileType: {
      default: "image",
      type: String,
    },
    params: {
      default: () => {},
      type: Object,
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.$emit("success", res, file);
    },
    beforeAvatarUpload(file) {
      let typeCheck = true,
        sizeCheck = true;
      if (this.fileType) {
        typeCheck = file.type.indexOf(this.fileType) > -1;
        if (!typeCheck) {
          this.$msg.error("文件格式错误");
        }
      }
      if (this.fileSize) {
        sizeCheck = file.size / 1024 / 1024 < Number(this.fileSize);
        if (!sizeCheck) {
          this.$msg.error(`文件不得大于${this.fileSize}M`);
        }
      }
      return typeCheck && sizeCheck;
    },
    handleDelete() {
      this.$emit("update:url", "");
    },
  },
};
</script>
<style lang="scss">
.common-upload {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .delete-box {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      background: #f56c6c;
      text-align: center;
      transform: rotate(45deg);
      i {
        transform: rotate(-45deg);
        color: #fff;
        line-height: 35px;
        display: inline-block;
        font-size: 12px;
      }
    }
  }
  .common-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
  }
  .common-upload-image {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>
