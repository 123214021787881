const routes = [
  {
    path: "/auth/",
    redirect: "/auth/userList2",
    children: [
      ///////////////////////////////////////////////////////////////////////////////////
      {
        path: "userList2",
        name: "用户列表2",
        component: () => import("@/views/auth/userMgt/userList2"),
        props: { rangeFlag: "global" },
      },
      {
        path: "global/roleList",
        name: "角色列表",
        component: () => import("@/views/auth/userMgt/roleList2"),
        props: { rangeFlag: "global" },
      },
      {
        path: "global/baseData",
        name: "基础数据",
        component: () => import("@/views/auth/configMgt/baseData"),
        props: { rangeFlag: "global" },
      },
      ///////////////////////////////////////////////////////////////////////////////////

      {
        path: "log",
        name: "操作日志",
        component: () => import("@/views/auth/logMgt/log"),
      },
    ],
  },
];
export default routes;
