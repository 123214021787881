import http from '@/utils/axios'
const api = {
  getUserInfo: () => http.get('/userInfo'),
  getAuthTree: (data) => http.get('tenant-user/auth/back_end/global/authTree', data),
  // getSystem: () => http.get("tenant-user/systemCatagory/back_end/query"),
  getEmployeeInfo: (data) => http.get('tenant-user/employeeInfo/query', data),
  userInfoModify: (data) => http.post('tenant-user/account/back_end/modify', data),
  updatedUserInfo: (data) => http.post('tenant-user/account/back_end/modify', data),
  // 修改密码
  updatePassword: (data) => http.post('/tenant-user/account/back_end/modify/password', data),
  fileUpload: (data) => http.post('/tenant-user/file/upload', data),
}
export default api
