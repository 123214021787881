<!--  -->
<template>
  <el-container class="container-main">
    <el-aside :width="isFoldSlider ? '50px' : '220px'" v-if="!$route.meta.hiddenMenu">
      <v-slider :leftMenuData="menuData" />
    </el-aside>
    <el-container>
      <el-header style="height: 45px">
        <v-header></v-header>
      </el-header>
      <el-main class="page-main">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import VHeader from './header.vue'
import VSlider from './slider.vue'
export default {
  components: { VHeader, VSlider },
  data() {
    return {}
  },
  computed: {
    ...mapState(['isFoldSlider', 'curSystem']),
    menuData() {
      return this.curSystem.children
    },
  },
}
</script>
<style lang="scss" scoped>
.el-header {
  padding: 0;
}
.container-main {
  height: calc(100vh);

  .page-main {
    height: calc(100vh - 65px);
    margin: 5px;
    padding: 12px;
    background: #fff;
    box-shadow: 0 2px 12px #0000001a;
    border-radius: 3px;
    overflow: auto;
  }
}
.el-main {
  padding: 0;
  overflow: hidden;
}

.fade-enter {
  opacity: 0; 
}
.fade-leave {
  opacity: 1; 
}
.fade-enter-active {
  transition: opacity 0.35s; 
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.35s; 
}
</style>
