export default {
  SET_LEFT_MENU_DATA(state, data) {
    state.leftMenuData = data
  },
  SET_FOLD_SLIDER_STATUS(state, data) {
    state.isFoldSlider = data
  },
  SET_CURRENT_ENV(state, data) {
    state.currentEnv = data
  },
  SET_USER_INFO(state, data) {
    state.userInfo = data
  },
  SET_CURRENT_SYS(state, data) {
    state.curSystem = data
  },
  SET_OPEN_TAGS(state, data) {
    state.openTags = data
  },
  SET_SYSTEM(state, data) {
    state.systemList = data
  },
  SET_OPENTAGS(state, data) {
    state.openTags = data
  },
  SET_AUTH_MENU(state, data) {
    state.authMenu = data
  },
}
