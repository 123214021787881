import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import Layout from '@/layout/index'

import devopsRoutes from './devops/index'
import operateRoutes from './operate/index'
import authRoutes from './auth/index'
import oauthRoutes from './oauth/index'

const moduleRoutes = [...devopsRoutes, ...operateRoutes, ...authRoutes,...oauthRoutes]

moduleRoutes.forEach((item) => (item.component = Layout))

Vue.use(VueRouter)
const router = new VueRouter({
  mode: "history",
  routes: [
    ...moduleRoutes,
    {
      path: '',
      component: () => import('@/layout/index'),
      children: [
        {
          path: '/',
          redirect: '/devops/sysFramework',
        },
        {
          path: '/404',
          name: '路径错误',
          component: () => import('@/views/404.vue'),
          meta: {
            hiddenMenu: true,
          },
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0)
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
