/* eslint-disable no-case-declarations */
import qs from 'qs'
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index.js'
import Vue from 'vue'

const config = {
  baseURL: '/api',
  timeout: 60 * 1000,
  withCredentials: false,
}

const axiosInstance = axios.create(config)

axiosInstance.interceptors.request.use(
  (config) => {
    const TOKEN = Vue.prototype.$cookie.get('access_token')
    TOKEN && (config.headers.token = TOKEN)
    config.headers['env'] = store.state.currentEnv
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 302) {
        window.location.href = response.data.data
        return Promise.resolve(response.data)
      }

      if (response.data.code === 1 || response.data.code === 200 || response.data.code === 20000) {
        return Promise.resolve(response.data)
      } else if (response.data.code === undefined) {
        return Promise.resolve(response)
      } else {
        Message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          message: response.data.message || response.data.msg,
          type: 'error',
        })
        return Promise.reject(response.data)
      }
    } else {
      Message({
        dangerouslyUseHTMLString: true,
        showClose: true,
        message: response.data.message,
        type: 'error',
      })
      return Promise.reject(response.data)
    }
  },
  (error) => {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 401:
          if (window.location.hostname !== 'localhost') {
            window.location.href = `${window.location.origin}/web${window.location.pathname}`
          } else {
            Message({
              showClose: true,
              message: '用户未登录',
              type: 'error',
            })
          }
          break
        case 404:
          Message({
            showClose: true,
            message: '路径不存在',
            type: 'error',
          })
          break
        case 403:
          break
        case 504:
          Message({
            showClose: true,
            message: '请求超时',
            type: 'error',
          })
          break
        default:
          Message.error(error.response?.data?.message)
      }
      return Promise.reject(error.response.data)
    }
  }
)

const http = {
  get(url, params) {
    return axiosInstance.get(url, { params })
  },
  post(url, params, config) {
    return axiosInstance.post(url, params, config)
  },
  delete(url, params) {
    return axiosInstance.delete(url, { params })
  },
  postform(url, params) {
    return axiosInstance.post(url, qs.stringify(params))
  },
  put(url, params) {
    return axiosInstance.put(url, params)
  },
}

export default http
