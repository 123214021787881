<template>
  <router-view v-if="showPage" />
</template>

<script>
import common from '@/common'
export default {
  name: 'App',
  data() {
    return {
      showPage: false,
    }
  },
  async created() {
    try {
      await common.getAuthTree()
    } catch (err) {
      console.error('权限菜单数据获取失败', err)
    }
    this.showPage = true
    common.getUserInfo()
  },
}
</script>

<style lang="scss">
@import 'assets/css/reset';
@import 'assets/css/public';
</style>
