<!-- 修改密码 -->
<template>
  <el-dialog title="修改密码" :visible="show" @close="close" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
    <div class="mb20 mr20 ml20" v-loading="loading">
      <el-form label-width="80px" :model="form" :rules="rules" ref="form" status-icon>
        <el-form-item label="原密码:" prop="oldPassword">
          <el-input v-model="form.oldPassword" placeholder="请输入密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" placeholder="请输入密码" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/common/index'
import { mapState } from 'vuex'
export default {
  props: {
    show: { type: Boolean, default: false },
    userInfo: { type: Object, default: () => {} },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else if (value.length < 8) {
        callback(new Error('至少输入8位数的密码'))
      } else {
        callback()
      }
    }
    let validateSurePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      form: {},
      rules: {
        oldPassword: [{ required: true, message: '原密码不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: validatePass, trigger: 'change' },
        ],
        confirmPassword: [{ validator: validateSurePass, trigger: 'blur' }],
      },
    }
  },
  computed: { ...mapState(['userInfo']) },
  watch: {
    show(val) {
      val && this.$refs.form?.resetFields()
    },
  },
  methods: {
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            let paramName = this.userInfo?.details?.accountType === 'general_user' ? 'generalAccount' : 'enterpriseAccount'
            const { id, account, name, email } = this.userInfo.details[paramName]
            let param = {
              ...this.form,
              name,
              email,
              id,
              account,
            }
            await api.updatePassword(param)
            this.$message.success('修改密码成功！')
            this.close()
            this.$emit('success')
          } finally {
            this.loading = false
          }
        }
      })
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>
<style lang="scss" scoped></style>
